.contentContainer {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    width: 70vw;
    height: 97vh;
    z-index: 1;
    border: none;
    border-radius: 40px 40px 0px 0px;
    opacity: 0;
    backdrop-filter: blur(20px);
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),

}

.contentContainer.open {
    transform: translateX(-50%) translateY(0);
    opacity: 1;
    visibility: visible;
    backdrop-filter: none;
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
}

.topBanner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    border-radius: 40px 40px 0px 0px;
    background: rgba(255, 255, 255, 0.70);
    backdrop-filter: blur(20px);
    height: 70px;
}

.childContentContainer {
    background: rgba(246, 246, 246, 0.85);
    backdrop-filter: blur(20px);
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    position: relative;

}

@media (max-width: 768px) {
    .contentContainer {
        width: 100vw;
    }
}

.logOutButton {
    position: absolute;
    bottom: 150px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
}
