.container {
    width: 500px;
    height: 350px;
    border-radius: 40px;
    transition: transform 0.5s ease, box-shadow 0.3s ease; // Add transition for smooth scaling and glow
    box-shadow: none;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            transform: scale(1.01);
            box-shadow: 0 0 20px rgba(0, 105, 233, 0.7);
        }
    }
}

.parentCard {
    width: 500px;
    height: 290px;
    flex-shrink: 0;
    border-radius: 40px 40px 0px 0px;
    background: rgba(255, 255, 255, 0.65);
    backdrop-filter: blur(0px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.lowerPrincipleCardBanner {
    width: 500px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 0px 0px 40px 40px;
    background: rgba(255, 255, 255, 0.60);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

@media (max-width: 480px) {
    .container {
        width: 300px;
        height: 210px;
        transition: none;
        
        &:hover {
            transform: none;
        }
    }

    .parentCard {
        width: 300px;
        height: 150px;
        border-radius: 20px 20px 0px 0px;
    }

    .lowerPrincipleCardBanner {
        width: 300px;
        height: 40px;
        border-radius: 0px 0px 20px 20px;
    }
}