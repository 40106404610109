.homePageContainer {
    max-width: 1030px;
    margin: 0px 10px 0px 10px;
    display: flex; // Add this line to enable flexbox layout
    flex-direction: column; // Add this line to stack items vertically
    justify-content: center; // Add this line to center items horizontally
    align-items: center;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    grid-gap: 30px;
    max-width: 1030px;
    margin-bottom: 30px;
    transition: opacity 0.7s ease-out, visibility 0.7s ease-out, transform 0.7s ease-out;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.infoActionBar {
    justify-content: flex-start;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
    transition: opacity 0.25s ease-out, visibility 0.25s ease-out, transform 0.25s ease-out;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

@media (max-width: 1200px) {
    .gridContainer {
        grid-template-columns: 1fr; /* Change to one column */
    }
}

.rightIcon {
    position: fixed;
    top: 30px;
    right: 30px;
}


/* Mobile breakpoint */
@media (max-width: 480px) {
    .homePageContainer {
        padding: 0 5px;
        max-width: 500px;
    }

    .gridContainer {
        grid-gap: 0px;
        margin-top: 30px;
    }

    .rightIcon {
        top: 20px;
        right: 10px;
    }

    .infoActionBar {
        display: none;
    }
}

.contentContainer {
    position: fixed;
    bottom: 0;
    left: auto;
    margin-top: 0px;
    width: 70vw;
    height: 97vh;
    z-index: 1;
    border: 1px solid #83901b;
    border-radius: 40px 40px 0px 0px;
}

.gridContainer.hidden {
    opacity: 0;
    visibility: hidden;
    transform: translateY(20px); 
    pointer-events: none; 
}

.infoActionBar.hidden {
    opacity: 0;
    visibility: hidden;
    opacity: 0;
    visibility: hidden;
    transform: translateY(20px); 
    pointer-events: none; 
}

@keyframes scaleUp {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes scaleDown {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

.settings-button.scaled .settings-icon,
.close-button.scaled {
    animation: scaleUp 0.3s ease-in-out;
}

.settings-button:not(.scaled) .settings-icon,
.close-button:not(.scaled) {
    animation: scaleDown 0.3s ease-in-out;
}